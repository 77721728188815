


































































































































































































































































































































































































































.formBOX {
  .form-item {
    margin-left: 2.5rem;
  }

  .el-form-item__content {
    width: 50%;
  }

  .el-input-number {
    width: 100%;
  }

  .form-itemteache {
    .el-form-item__content {
      display: flex;
      align-items: center;
      margin-left: 0;
    }
  }

  .course {
    .el-form-item__content {
      padding-top: 0.3125rem;
    }
  }

  .form-btn {
    .el-form-item__content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .el-button {
      width: 10rem;
    }
  }
}

.el-select {
  width: 100% !important;
}

.el-icon-arrow-down {
  font-size: 12px;

  &:before {
    content: "\e6df" !important;
  }
}

.upload-btn {
  height: 40px;

  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
